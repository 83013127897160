































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class ToggleContent extends Vue {
  visible: boolean = false;

  @Prop({ default: '' }) title: string;
}
