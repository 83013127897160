















































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import { StoreFacebookPage } from '@/shared/store/facebook';
import SocialProfileIcon from '@/shared/components/common/SocialProfileIcon.vue';

@Component({
  components: {
    SocialProfileIcon,
  },
})
export default class SocialConnection extends Vue {
  @Prop({ required: true }) facebookPage: StoreFacebookPage;

  get instagramAccount() {
    const { instagram = [] } = this.facebookPage;
    if (instagram.length > 0) {
      return instagram[0];
    }
    return undefined;
  }
}
