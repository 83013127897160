
































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import Pagination from '@/shared/components/common/Pagination.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import { Charge } from '@/shared/gen/messages.pisa';

const BillingsTableProps = Vue.extend({
  props: {},
});
@Component({
  components: { Pagination, LoadingIndicator },
})
export default class BillingsTable extends BillingsTableProps {
  $refs!: any;

  currentPage: number = 1;

  perPage: number = 10;

  @Getter('billingHistory/getCharges') billingData: Charge[];

  @State((state) => state.billingHistory.loaded) historyLoaded: boolean;

  fields: Array<object> = [{ key: 'created', label: 'Transaction Date' }, { key: 'description', label: 'Description' },
    { key: 'last4', label: 'Card Number ending in' }, { key: 'amount', label: 'Total' }];

  get mobileData() {
    const begin = ((this.currentPage - 1) * this.perPage);
    const end = begin + this.perPage;

    return this.billingData.slice(begin, end);
  }

  get isMobile() {
    return this.$store.getters['layout/isMobile'];
  }

  description(data: { item: Charge }) {
    const charge = data.item;
    if (charge.couponCode) {
      return `${charge.description} ** w/ ${charge.couponCode} COUPON **`;
    }
    return charge.description;
  }

  beforeCreate() {
    this.$store.dispatch('billingHistory/load');
  }

  stripeBrandToIcon(brand: string) {
    switch (brand) {
      case 'amex':
      case 'discover':
      case 'jcb':
      case 'mastercard':
      case 'visa':
        return ['fab', `cc-${brand}`];
      case 'diners':
        return ['fab', 'cc-diners-club'];
      default:
        return ['fas', 'credit-card'];
    }
  }
}
