




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SocialProfileIcon extends Vue {
  @Prop({ default: '' })
  icon: string;

  @Prop({ required: true })
  src: string;

  @Prop({ default: 50 })
  size: number;

  get imageStyle() {
    return {
      width: `${this.sizeWithoutBorder}px`,
      height: `${this.sizeWithoutBorder}px`,
    };
  }

  get iconStyle() {
    return {
      bottom: `-${this.size / 10}px`,
      right: `-${this.size / 10}px`,
      fontSize: `${(2 * this.size) / 5}px`,
      width: `${(2 * this.size) / 5}px`,
    };
  }

  private get sizeWithoutBorder() {
    return this.size - 2;
  }
}
