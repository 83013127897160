



















































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import defaultToast from '@/shared/lib/defaultToast';
import { CampaignListItem } from '@/shared/store/campaign';
import { isInUseError, StoreFacebookPage } from '@/shared/store/facebook';

import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import PromptModal from '@/shared/components/modals/PromptModal.vue';
import SocialConnection from '@/shared/components/settings/SocialConnection.vue';
import SyncNewFacebookPageModal from '@/shared/components/modals/social/SyncNewFacebookPageModal.vue';
import BandlabConnection from '@/shared/components/settings/BandlabConnection.vue';

@Component({
  components: {
    LoadingIndicator,
    PromptModal,
    SocialConnection,
    SyncNewFacebookPageModal,
    BandlabConnection,
  },
})
export default class SocialConnections extends Vue {
  $refs!: {
    syncNewFacebookPageModal: any,
    socialConnectionInUseModal: any,
    confirmDisconnectModal: any,
  };

  loading: boolean = true;

  selectedFacebookPage: StoreFacebookPage | null = null;

  disconnectInProgress: boolean = false;

  @Getter('profile/bandlabAccount') bandlabAccount: string;

  @Getter('facebook/getSyncedPages') syncedPages: StoreFacebookPage[];

  @Getter('campaign/running') activeCampaigns: CampaignListItem[];

  mounted() {
    Promise.all([
      this.$store.dispatch('facebook/loadSyncedPages'),
      this.$store.dispatch('campaign/list'),
    ]).then(() => {
      this.loading = false;
    }).catch((reason) => {
      defaultToast('Failed to load social connections.  Please try again.');
      return Promise.reject(reason);
    });
  }

  get sortedSyncedPages() {
    return this.syncedPages.slice(0).sort((lhs, rhs) => {
      // Instagram first!
      const lhsInstagramLength = lhs.instagram!.length;
      const rhsInstagramLength = rhs.instagram!.length;
      if (lhsInstagramLength > rhsInstagramLength) {
        return -1;
      }
      if (lhsInstagramLength < rhsInstagramLength) {
        return 1;
      }
      // Case insensitive compare...
      const lhsName = lhs.name.toLowerCase();
      const rhsName = rhs.name.toLowerCase();
      if (lhsName < rhsName) {
        return -1;
      }
      if (lhsName > rhsName) {
        return 1;
      }
      return 0;
    });
  }

  get activeCampaignsForSelectedSocialConnections() {
    const { selectedFacebookPage } = this;
    return this.activeCampaigns.filter((i) => {
      const { facebookPage: campaignFacebookPage } = i.campaign;
      if (campaignFacebookPage && campaignFacebookPage.pageId) {
        return !selectedFacebookPage || campaignFacebookPage.pageId === selectedFacebookPage.pageId;
      }
      return false;
    }).length;
  }

  get confirmDisconnectTitle() {
    if (this.selectedFacebookPage) {
      return 'Remove this page?';
    }
    return 'Remove these pages?';
  }

  disconnectSelectedFacebookPage(facebookPage: StoreFacebookPage | null) {
    this.selectedFacebookPage = facebookPage;
    this.disconnectInProgress = false;
    if (this.activeCampaignsForSelectedSocialConnections !== 0) {
      this.$refs.socialConnectionInUseModal.show();
      return;
    }
    this.$refs.confirmDisconnectModal.show();
  }

  handleDisconnect(facebookPage: StoreFacebookPage) {
    this.disconnectSelectedFacebookPage(facebookPage);
  }

  handleDisconnectConfirm() {
    this.disconnectInProgress = true;
    let disconnectPromise;
    const { selectedFacebookPage } = this;
    if (selectedFacebookPage && selectedFacebookPage.pageId) {
      disconnectPromise = this.$store.dispatch('facebook/disconnectPage', selectedFacebookPage.pageId);
    } else {
      disconnectPromise = this.$store.dispatch('facebook/disconnectAllPages');
    }
    disconnectPromise
      .then(() => {
        this.$refs.confirmDisconnectModal.hide();
      })
      .catch((reason) => {
        this.disconnectInProgress = false;
        return this.handleDisconnectError(reason);
      });
  }

  handleAdd() {
    this.$refs.syncNewFacebookPageModal.show();
  }

  handleDisconnectAll() {
    this.disconnectSelectedFacebookPage(null);
  }

  handleDisconnectError(reason: any) {
    if (isInUseError(reason)) {
      this.$refs.socialConnectionInUseModal.show();
      return Promise.resolve();
    }
    defaultToast(`Failed to disconnect social connection${this.selectedFacebookPage ? '' : 's'}.  Please try again.`);
    return Promise.reject(reason);
  }
}
