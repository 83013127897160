






























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SyncFacebookPageAccountDetails extends Vue {
  @Prop({ required: true })
  page: any;

  get facebookName(): string {
    if (this.page) {
      return this.page.name;
    }
    return '';
  }

  get facebookPictureUrl(): string {
    if (this.page) {
      return this.page.pictureUrl;
    }
    return '';
  }

  get instagramUsername(): string {
    if (this.hasInstagram) {
      return this.page.instagram[0].username;
    }
    return this.facebookName;
  }

  get instagramPictureUrl(): string {
    if (this.hasInstagram) {
      return this.page.instagram[0].pictureUrl;
    }
    return this.facebookPictureUrl;
  }

  get hasInstagram(): boolean {
    return this.page && this.page.instagram && this.page.instagram.length > 0;
  }
}
