


































import { Component, Prop, Vue } from 'vue-property-decorator';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import ModalButton from '@/shared/components/modals/ModalButton.vue';

@Component({
  components: {
    ModalButton,
    SimpleModal,
  },
})
export default class PromptModal extends Vue {
  $refs!: {
    modal: SimpleModal & { show: () => {}, hide: (trigger?: string) => {} },
  };

  @Prop({ required: true }) modalId: string;

  @Prop({ required: true }) title: string;

  @Prop({ required: true }) callToAction: string;

  @Prop({ default: false, type: Boolean }) processing: boolean;

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  handleSubmit() {
    this.$emit('submit');
  }
}
