








































































































































































/// <reference types="stripe-v3" />
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { StripeStatic, ProcessingErrorMessage } from '@/shared/store/payment';
import * as visaMono from '@/assets/common/cc_img/mono/visa.svg';
import * as visaFlat from '@/assets/common/cc_img/flat/visa.svg';
import * as mcMono from '@/assets/common/cc_img/mono/mastercard.svg';
import * as mcFlat from '@/assets/common/cc_img/flat/mastercard.svg';
import * as amexMono from '@/assets/common/cc_img/mono/amex.svg';
import * as amexFlat from '@/assets/common/cc_img/flat/amex.svg';
import * as discoverMono from '@/assets/common/cc_img/mono/discover.svg';
import * as discoverFlat from '@/assets/common/cc_img/flat/discover.svg';
import { PaymentMethodResponse } from '@/shared/gen/messages.pisa';

@Component({
  mixins: [],
  components: {},
})
export default class Card extends Vue {
  @Prop({ default: true })
  checkout: boolean;

  card: stripe.elements.Element;

  cardCvc: stripe.elements.Element;

  cardExp: stripe.elements.Element;

  isSafari: boolean = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  @Getter('payment/getErrors') getCheckoutErrors: ProcessingErrorMessage[];

  @Getter('payment/getPayment') repeatPayment: PaymentMethodResponse;

  cardType: string = '';

  savePayment: string = 'false';

  submitted = false;

  cardFinished: Array<boolean> = [false, false, false];

  editing: boolean = false;

  addCard() {
    this.editing = true;
  }

  // HACK: this is a terrible way to flash the error messages when the user hits submit.  Much easier
  // to disable the purchase button until everything is 'complete', but here we are...
  get cardError() {
    return this.cardErrorStripe || (this.submitted && !this.cardComplete ? 'Please enter a valid card number' : '');
  }

  cardErrorStripe: string | undefined = '';

  cardComplete = false;

  get expiryError() {
    return this.expiryErrorStripe || (this.submitted && !this.expiryComplete ? 'Please enter a valid expiration date' : '');
  }

  expiryErrorStripe: string | undefined = '';

  cvcErrorStripe: string | undefined = '';

  get cvcError() {
    return this.cvcErrorStripe || (this.submitted && !this.cvcComplete ? 'Please enter a valid security code' : '');
  }

  expiryComplete = false;

  cvcComplete = false;

  get cardIconSrc() {
    switch (this.cardType) {
      case 'visa':
        return visaFlat;
      case 'mastercard':
        return mcFlat;
      case 'amex':
        return amexFlat;
      case 'discover':
        return discoverFlat;
      default:
        return null;
    }
  }

  get hasSavedCard() {
    return this.repeatPayment.last4;
  }

  get complete() {
    return !this.hasError && this.cardComplete && this.expiryComplete && this.cvcComplete;
  }

  get hasError() {
    return this.cardError !== '' || this.expiryError !== '' || this.cvcError !== '' || this.getCheckoutErrors.length > 0;
  }

  get visaSrc() {
    // if (this.cardType === 'visa') {
    //   return visaFlat;
    // }
    return visaMono;
  }

  get mcSrc() {
    // if (this.cardType === 'mastercard') {
    //   return mcFlat;
    // }
    return mcMono;
  }

  get amexSrc() {
    // if (this.cardType === 'amex') {
    //   return amexFlat;
    // }
    return amexMono;
  }

  get discoverSrc() {
    // if (this.cardType === 'discover') {
    //   return discoverFlat;
    // }
    return discoverMono;
  }

  get inputCompleted() {
    return this.cardFinished.filter((c) => c).length === 3;
  }

  get repeatPaymentHasData() {
    return Object.keys(this.repeatPayment).length !== 0;
  }

  iconClass(cardIcon: string):string {
    if (!this.cardType || this.cardType === cardIcon) {
      return '';
    }
    return 'disabled';
  }

  mounted() {
    // Floating labels
    const inputs = document.querySelectorAll('.card-component .input');
    Array.prototype.forEach.call(inputs, (input: any) => {
      input.addEventListener('focus', () => {
        input.classList.add('focused');
      }, true);
      // input.addEventListener('blur', () => {
      //   input.classList.remove('focused');
      // }, true);
      input.addEventListener('focusout', () => {
        input.classList.remove('focused');
      }, true);
      input.addEventListener('keyup', () => {
        if (input.value && input.value.length === 0) {
          input.classList.add('empty');
        } else {
          input.classList.remove('empty');
        }
      }, true);
    });

    const elements = StripeStatic.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Catamaran:400,500',
        },
      ],
    });

    const elementStyles = {
      base: {
        color: '#0C0E0F',
        fontWeight: 500,
        fontFamily: 'Catamaran',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '35px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#0C0E0F',

        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };

    const elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    const cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardNumber.mount('#card-number');

    const cardExpiry = elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardExpiry.mount('#card-expiry');

    const cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardCvc.mount('#card-cvc');

    this.card = cardNumber;
    this.cardExp = cardExpiry;
    this.cardCvc = cardCvc;

    // const error = document.querySelector('.error');
    // const errorMessage: HTMLElement | null = error!.querySelector('.message');

    // const savedErrors:any = {};

    cardNumber.on('change', (event) => {
      this.submitted = false;
      this.cardComplete = event!.complete;
      if (event!.brand && event!.brand !== 'unknown') {
        this.cardType = event!.brand;
      } else {
        this.cardType = '';
      }
      if (event!.error) {
        this.cardErrorStripe = event!.error!.message;
      } else {
        if (event!.complete) {
          this.cardFinished.splice(0, 1, true);
        }
        this.cardErrorStripe = '';
        this.$store.commit('payment/resetErrors');
      }
    });

    cardExpiry.on('change', (event) => {
      this.submitted = false;
      this.expiryComplete = event!.complete;
      if (event!.error) {
        this.expiryErrorStripe = event!.error!.message;
      } else {
        if (event!.complete) {
          this.cardFinished.splice(1, 1, true);
        }
        this.expiryErrorStripe = '';
        this.$store.commit('payment/resetErrors');
      }
    });

    cardCvc.on('change', (event) => {
      this.cvcComplete = event!.complete;
      if (event!.error) {
        this.cvcErrorStripe = event!.error!.message;
      } else {
        if (event!.complete) {
          this.cardFinished.splice(2, 1, true);
        }
        this.cvcErrorStripe = '';
        this.$store.commit('payment/resetErrors');
      }
    });
  }

  cancelChange() {
    this.card.clear();
    this.cardCvc.clear();
    this.cardExp.clear();
    this.savePayment = 'false';
    if (this.repeatPaymentHasData && this.repeatPayment.brand) {
      this.cardType = this.repeatPayment.brand.toLowerCase();
      this.$emit('toggle');
    } else {
      this.editing = false;
    }
  }

  focusNumber() {
    if (!this.isSafari) {
      this.card.focus();
    }
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.$emit('Submit', evt);
  }

  saveCard() {
    if (!this.hasError) {
      this.$store.dispatch('payment/save', { elements: this.card, savePayment: true }).then(() => {
        this.card.clear();
        this.cardCvc.clear();
        this.cardExp.clear();
        if (this.repeatPayment.last4) {
          this.$emit('toggle');
        }
      });
    }
  }
}
