



















import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import { BL_LOGIN, BL_ROOT } from '@/shared/lib/api';

@Component
export default class BandlabConnection extends Vue {
  @Prop({ default: '' })
  account: string;

  get accountLink() {
    return `${BL_ROOT}/${this.account}`;
  }

  connectBL() {
    if (BL_LOGIN) window.location.href = `${BL_LOGIN}?redirect=${window.location.pathname}`;
  }
}
