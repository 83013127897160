































































/// <reference types="stripe-v3" />
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { PaymentMethodResponse } from '@/shared/gen/messages.pisa';
import * as visaSingle from '@/assets/common/cc_img/single/visa.svg';
import * as mcSingle from '@/assets/common/cc_img/single/mastercard.svg';
import * as amexSingle from '@/assets/common/cc_img/single/amex.svg';
import * as discoverSingle from '@/assets/common/cc_img/single/discover.svg';
import * as genericMono from '@/assets/common/cc_img/mono/default.svg';

@Component({
  mixins: [],
  components: {},
})
export default class RepeatCard extends Vue {
  @Getter('payment/getPayment') repeatPayment: PaymentMethodResponse;

  @Prop({ default: false })
  cardExpired: boolean;

  @Prop({ default: false })
  hideDelete: boolean;

  get cardIconSrc() {
    const cardBrand = this.repeatPayment.brand || '';
    switch (cardBrand.toLowerCase()) {
      case 'visa':
        return visaSingle;
      case 'mastercard':
        return mcSingle;
      case 'amex':
      case 'american express':
        return amexSingle;
      case 'discover':
        return discoverSingle;
      default:
        return genericMono;
    }
  }

  removeCard() {
    this.$store.dispatch('payment/delete').then(() => {
      this.$store.dispatch('payment/load').then(() => {
        if (this.repeatPayment.last4) {
          // payment method should have been deleted, and this should be blank...
          window.location.reload();
        } else {
          this.$emit('toggle');
        }
      });
    });
  }
}
