
































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CookiePreferences from '@/shared/components/site/CookiePreferences.vue';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { Consent, Scope } from '@/shared/store/cookieConsent';

@Component({
  components: { CookiePreferences },
})
export default class CookieSettings extends Vue {
  @Getter('cookieConsent/scopes') scopes: Scope[];

  @Getter('cookieConsent/granted') granted: string[];

  preferences: { [key: string]: boolean } = {};

  created() {
    this.resetPreferences();
  }

  resetPreferences() {
    this.preferences = {};
    this.scopes.forEach((i) => {
      this.preferences[i.name] = this.granted.indexOf(i.name) !== -1;
    });
  }

  savePreferences() {
    const consent: Consent[] = this.scopes.map((i) => ({ name: i.name, version: i.version, granted: this.preferences[i.name] }));
    this.$store.dispatch('cookieConsent/update', { consent })
      .catch(() => {
        defaultErrorToast('Unexpected error saving cookie preferences');
      })
      .finally(() => {
        this.resetPreferences();
      });
  }
}
