












































































































































































import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IPaymentMethodResponse } from '@/shared/gen/messages.pisa';
import defaultToast from '@/shared/lib/defaultToast';

import AuthRequired from '@/shared/components/AuthRequired';
import BillingsTable from '@/shared/components/common/BillingsTable.vue';
import Card from '@/shared/components/stripe/Card.vue';
import RepeatCard from '@/shared/components/stripe/RepeatCard.vue';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import SocialConnections from '@/shared/components/settings/SocialConnections.vue';
import ToggleContent from '@/shared/components/settings/ToggleContent.vue';
import UpdateEmail from '@/shared/components/form/UpdateEmail.vue';
import UpdatePassword from '@/shared/components/form/UpdatePassword.vue';
import CookieSettings from '@/zire/views/CookieSettings.vue';

@Component({
  components: {
    CookieSettings,
    BillingsTable,
    Card,
    RepeatCard,
    SiteLayout,
    SocialConnections,
    ToggleContent,
    UpdateEmail,
    UpdatePassword,
  },
})
export default class Settings extends AuthRequired {
  $refs!: any;

  @Getter('profile/userEmail') userEmail: string;

  @Getter('profile/bandlabAccount') loggedInBl: string;

  @Getter('payment/getPayment') savedCard: IPaymentMethodResponse;

  @Getter('cookieConsent/hasSavedConsent') hasSavedCookieConsent: boolean;

  consents = this.$store.getters['profile/consentMap'];

  emailPrefChange(evt: Event) {
    const target = (evt.target as HTMLInputElement);
    target.disabled = true;

    const payload = { consent: 'marketing', granted: this.consents.marketing };
    this.$store.dispatch('profile/updateConsent', payload).catch(() => {
      defaultToast();
      this.consents.marketing = !this.consents.marketing;
    }).finally(() => {
      target.disabled = false;
    });
  }

  reusePaymentInformation: boolean = false;

  isMobile = window.innerWidth < 1096;

  errors: Array<string> = [];

  billingHistoryShown: boolean = false;

  mounted() {
    this.$store.dispatch('payment/load').then(() => {
      if (Object.keys(this.savedCard).length !== 0) {
        this.reusePaymentInformation = true;
      }
    });
  }

  get inlineCardError() {
    if (this.$refs.cardForm) {
      return this.$refs.cardForm.cardError || this.$refs.cardForm.expiryError || this.$refs.cardForm.cvcError;
    }
    return true;
  }

  get cardExpired() {
    if (Object.keys(this.savedCard).length === 0) {
      return false;
    } if (this.savedCard && this.savedCard.expYear && this.savedCard.expMonth) {
      const currentDate = new Date();
      if (this.savedCard.expYear < currentDate.getFullYear()) {
        return true;
      }
      return this.savedCard.expYear === currentDate.getFullYear() && this.savedCard.expMonth < (currentDate.getMonth() + 1);
    }
    return false;
  }

  toggleReusePayment() {
    this.reusePaymentInformation = !this.reusePaymentInformation;
    this.$refs.settingCard.cardType = '';
  }

  get displayCheckoutError() {
    return 'An error occurred while processing your card. Please review your payment details and try again.';
  }
}
