




















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { passwordRule } from '@/shared/lib/validationRules';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';

const SettingsProps = Vue.extend({
  props: {},
});
@Component({
  mixins: [validationMixin],
  components: {
    MaterialInputField,
  },
  validations: {
    newPassword: { passwordRule },
  },
})
export default class Settings extends SettingsProps {
  $refs!: any;

  @Prop({ default: '' })
  userEmail: string;

  pwd: string = '';

  newPwd: string = '';

  isMobile = window.innerWidth < 1096;

  error = '';

  touchMap = new WeakMap();

  pwdVisible: boolean = false;

  newPwdVisible: boolean = false;

  delayTouch($v: any) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 1000));
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  resetElement($v: any) {
    $v.$reset();
  }

  get newPassword() {
    return this.newPwd;
  }

  set newPassword(value: string) {
    this.newPwd = value;
  }

  mounted() {
    this.pwd = '';
    this.newPwd = '';
    this.$store.dispatch('payment/load');
  }

  togglePwdVisibility(e: any, type: string) {
    if (type === 'pwd') {
      this.pwdVisible = !this.pwdVisible;
    } else {
      this.newPwdVisible = !this.newPwdVisible;
    }
    return false;
  }

  updatePassword() {
    this.$store.dispatch('profile/updatePassword', { oldPassword: this.pwd, newPassword: this.newPwd })
      .then(() => {
        defaultErrorToast('Password updated!', 'success');
      })
      .finally(() => {
        this.pwd = '';
        this.newPwd = '';
        this.$v.$reset();
      })
      .catch((error) => {
        switch (error.message) {
          case 'invalid password':
            this.error = 'Invalid password';
            break;
          default:
            this.error = 'Sorry, we were unable to update your password';
        }
      });
  }

  scrollIntoView(id: string) {
    if (this.isMobile) {
      const elem = document.getElementById(id);
      if (elem) {
        const yCoordinate = elem.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -150;
        window.scrollTo({
          top: yCoordinate + yOffset,
          behavior: 'smooth',
        });
      }
    }
  }
}
