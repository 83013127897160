

































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import defaultErrorToast from '@/shared/lib/defaultToast';
import { AGENCY_SCOPE } from '@/shared/lib/facebook';
import {
  isMissingPermissionsError,
  isPageNotPromotionEligibleError,
  isPageNotPublishedError,
  isPermissionDeniedError,
  isTemporarilyBlockedPolicyViolationError,
} from '@/shared/store/facebook';

import ModalButton from '@/shared/components/modals/ModalButton.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import SyncFacebookPageAccountDetails from '@/shared/components/modals/social/SyncFacebookPageAccountDetails.vue';

@Component({
  components: {
    ModalButton,
    SimpleModal,
    SyncFacebookPageAccountDetails,
  },
})
export default class SyncNewFacebookPageModal extends Vue {
  $refs!: {
    modal: SimpleModal,
  };

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('facebook/getPages') pages: any[];

  @Getter('facebook/getSyncedPages') syncedPages: any[];

  selectedPage: any = null;

  selectedPageId: string = '-1';

  submitting: boolean = false;

  isRerequest: boolean = false;

  get syncedPageIds() {
    return this.syncedPages.map((i) => i.pageId);
  }

  get nonSyncedPages() {
    return this.pages.filter((page) => this.syncedPageIds.indexOf(page.pageId) === -1);
  }

  get hasInstagram() {
    return this.selectedPage && this.selectedPage.instagram && this.selectedPage.instagram.length > 0;
  }

  selectPage() {
    this.selectedPage = this.nonSyncedPages.find((i) => i.pageId === this.selectedPageId);
  }

  handleSubmit() {
    this.submitting = true;
    const page = this.selectedPage;
    this.$store.dispatch('facebook/syncPage', page).then((result) => {
      this.$emit('syncNewFacebookPage', result);
      this.hide();
    }).catch((reason) => {
      this.submitting = false;
      if (isPermissionDeniedError(reason)) {
        defaultErrorToast('It appears you do not have the necessary permission/role for this Facebook page.');
      } else if (isPageNotPublishedError(reason)) {
        defaultErrorToast('This page couldn\'t be added to your campaign because it is not published.');
      } else if (isPageNotPromotionEligibleError(reason)) {
        defaultErrorToast('This page couldn\'t be added to your campaign because Facebook has restricted it from advertising.'
          + ' For more information please visit the Facebook Help Center.');
      } else if (isTemporarilyBlockedPolicyViolationError(reason)) {
        defaultErrorToast('This page couldn\'t be added to your campaign because Facebook has blocked you from using this feature.'
          + ' For more information please visit the Facebook Help Center.');
      } else {
        defaultErrorToast('Failed to add new Facebook Page.');
        this.$honeybadger.notify('Failed to add new Facebook Page.', 'SyncNewFacebookPageError', { context: { reason } });
      }
    });
  }

  handleHidden() {
    this.submitting = false;
  }

  show() {
    const options: facebook.LoginOptions = {
      scope: AGENCY_SCOPE,
    };
    if (this.isRerequest) {
      options.auth_type = 'rerequest';
    }
    this.$store.dispatch('facebook/facebookAuth', options)
      .then(() => this.$store.dispatch('facebook/loadPages'))
      .then(() => {
        this.$refs.modal.show();
      }).catch((reason) => {
        if (isMissingPermissionsError(reason)) {
          this.isRerequest = true;
          defaultErrorToast('To run an ad, we need all requested permissions to manage your campaign. Please try again.');
        } else {
          defaultErrorToast('We were unable to connect to your Facebook page. Please try again.');
        }
      });
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  @Watch('nonSyncedPages')
  watchNonSyncedPages(value: any[]) {
    if (value.length === 0) {
      this.selectedPageId = '-1';
      return;
    }

    this.selectedPageId = value[0].pageId;
    this.$nextTick(() => {
      this.selectPage();
    });
  }
}
