





























































import { Component, Vue } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { Getter } from 'vuex-class';
import UpdateEmailFields from '@/shared/components/form/UpdateEmailFields.vue';

@Component({
  mixins: [validationMixin],
  components: {
    UpdateEmailFields,
  },
})
export default class UpdateEmail extends Vue {
  $refs!: {
    updateEmailFields: UpdateEmailFields,
  };

  invalid: boolean = true;

  updateInProgress: boolean = false;

  newEmail: string = '';

  @Getter('profile/userEmail') userEmail: string;

  updateEmail() {
    if (this.invalid) {
      return;
    }
    this.updateInProgress = true;
    this.$refs.updateEmailFields!.updateEmail().then((email: string | boolean) => {
      if (typeof email === 'string') {
        this.newEmail = email;
      }
    }).finally(() => {
      this.updateInProgress = false;
    });
  }

  resendEmail() {
    this.$store.dispatch('profile/resendVerifyEmail');
  }
}
